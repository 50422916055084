<template>
  <div class="case-group">
    <v-header :title="title"></v-header>
    <van-index-bar :index-list="index" @select="select" class="index-bar">
      <template v-for="(i, one) in list">
        <van-index-anchor :index="one+1">{{ one + 1 }}</van-index-anchor>
        <div
          style="display: flex;flex-direction: column;align-items: center;justify-content: center;padding: 30px 28px;">
          <plan-card :detail="i" @handle-click="clickItem"/>
        </div>
      </template>
    </van-index-bar>

    <div class="current" v-if="show">
      {{ current }}
    </div>

    <div style="height: 50px;width: 100%;"></div>
    <operation :info="info" @operation="operation" page="group" @unlock="getList" @action="onOperationAction"></operation>
  </div>
</template>

<script>
import vCase from '@/components/case'
import {scheme} from '@/api'
import {nav} from "@/config/nav";
import {planListV2Api} from "../../api/v2/planListV2Api";
import PlanListOperation from "./components/planListOperation";
import PlanCard from "../../components/planCard";

export default {
  name: "caseGroup",
  components: {PlanCard, vCase,Operation: PlanListOperation},
  data() {
    return {
      showCurrent: false,
      current: 1,
      index: [],
      show: false,
      title: '',
      list: [],
      hideInterval: null,
      info: {}
    }
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    async getList() {
      const model = await planListV2Api.findAppletDetailById(this.$route.params.id)
      this.info = model
      // const data = model.planList
      const data = (await planListV2Api.findPlanPageByPlanListId({planListId: this.$route.params.id, current: 1,size: 1000,pageSize: 1000})).records
      this.list = data;
      data.forEach((item, i) => {
        this.index.push(i + 1);
      });
      this.title = `合集方案(${data.length}份)`;
    },

    clickItem(data) {
      nav.goPlanDetail(data.id)
    },

    select(index) {
      //this.show = true;
      this.show = true
      this.current = index;
      if (this.hideInterval) {
        clearInterval(this.hideInterval);
      }
      this.hideInterval = setInterval(() => {
        this.show = false;
      }, 2000);
      // console.log(index);
    },


    onOperationAction(e){
      const {action} = e
      if(action === 'collect'){
        this.info.clickCollect = !this.info.clickCollect
      }else if(action === 'recommend'){
        this.info.clickRecommend = !this.info.clickRecommend
      }else if(action === 'unlock'){
        this.info.pay = true
      }
    },


    operation() {
      this.getInfo();
    },
  }
}
</script>

<style scoped lang="less">
.case-group {
  background: var(--page-color);

  .index-bar {
    //height: calc(100vh - var(--header-height));
    margin-top: var(--header-height);
  }

  .local-index {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    background: rgba(00, 0, 0, .5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .current {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    //margin-top: -20px;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}

</style>

<style>
.van-index-bar__index {
  padding: 3px 8px;
}
</style>
